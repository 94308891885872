import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { BehaviorSubject, Observable } from 'rxjs';
import { MaterialApplicationMethod } from '../model/material-application-method.model';

@Injectable({ providedIn: 'root' })
export class ApplicationMethodService {

  constructor(private httpClient: HttpClient) { }

  private _materialMethodOptions: MaterialApplicationMethod[] = undefined;
  private subject = new BehaviorSubject(this._materialMethodOptions);
  private _materialMethodOptions$: Observable<MaterialApplicationMethod[]> = this.subject.asObservable();

  get materialMethodOptions(): MaterialApplicationMethod[] {
    return this._materialMethodOptions;
  }

  set materialMethodOptions(value: MaterialApplicationMethod[]) {
    this._materialMethodOptions = value;
    this.subject.next(this._materialMethodOptions);
  }

  get materialMethodOptions$(): Observable<MaterialApplicationMethod[]> {
    return this._materialMethodOptions$;
  }

  get(materialForm: any): Observable<MaterialApplicationMethod[]> {
    return this.httpClient.get(URLs.MATERIAL_APPLICATION_METHOD_ENDPOINT + '/material-form/' + materialForm) as
      Observable<MaterialApplicationMethod[]>;
  }

  all(): Observable<MaterialApplicationMethod[]> {
    return this.httpClient.get(URLs.MATERIAL_APPLICATION_METHOD_ENDPOINT) as
      Observable<MaterialApplicationMethod[]>;
  }
}
