import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { Observable } from 'rxjs';
import { MaterialApplicationIncorporation } from '../model/material-application-incorporation.model';

@Injectable({ providedIn: 'root' })
export class ApplicationIncorporationService {
  constructor(private httpClient: HttpClient) {}

  all(): Observable<MaterialApplicationIncorporation[]> {
    return this.httpClient.get(URLs.MATERIAL_APPLICATION_INCORPORATION_ENDPOINT) as Observable<MaterialApplicationIncorporation[]>;
  }
}
