import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaterialDryMatterRange } from '@shared/models/material/material-dry-matter-range';
import { MaterialTypeOption } from '@shared/models/material/material-type-option';
import { Observable } from 'rxjs';
import { URLs } from '../../../core/conf/urls';
import { OrganicAmendmentWorksheet } from '../model/organic-amendment-worksheet.model';

@Injectable({
  providedIn: 'root'
})
export class OrganicAmendmentService {
  takeWhile(arg0: () => boolean) {
    throw new Error('Method not implemented.');
  }

  constructor(private httpClient: HttpClient) {}

  get(id: string): Observable<OrganicAmendmentWorksheet> {
    return this.httpClient.get(`${URLs.ORGANIC_AMENDMENT_ENDPOINT}/${id}`) as Observable<OrganicAmendmentWorksheet>;
  }

  save(worksheet: OrganicAmendmentWorksheet): Observable<any> {
    return this.httpClient.post(URLs.ORGANIC_AMENDMENT_ENDPOINT, worksheet);
  }

  update(worksheet: OrganicAmendmentWorksheet): Observable<any> {
    const url = `${URLs.ORGANIC_AMENDMENT_ENDPOINT}/${worksheet.id}`;
    return this.httpClient.put(url, worksheet);
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(`${URLs.ORGANIC_AMENDMENT_ENDPOINT}/${id}`);
  }

  getAllMaterialTypeOptions(): Observable<MaterialTypeOption[]> {
    return this.httpClient.get(URLs.MATERIAL_TYPE_LOOPUPS_ENDPOINT) as Observable<MaterialTypeOption[]>;
  }

  getAllDryMatterRanges(): Observable<MaterialDryMatterRange[]> {
    return this.httpClient.get(URLs.MATERIAL_DRY_RANGES_ENDPOINT) as Observable<MaterialDryMatterRange[]>;
  }

  getMaterialTypeOption(id): Observable<MaterialTypeOption> {
    return this.httpClient.get(`${URLs.MATERIAL_TYPE_LOOKUP_ENDPOINT}/${id}`) as Observable<MaterialTypeOption>;
  }
}
